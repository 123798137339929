import "./ServisOur.css";
import { useTranslation } from "react-i18next";
import { motion } from 'framer-motion';
import BtnCall from "../../HelpComp/BtnCall/BtnCall";
import s1 from "../../img/icons/image/Service.png";
import s3 from "../../img/icons/image/Service3.png";
import s4 from "../../img/icons/image/Service4.png";
import s5 from "../../img/icons/image/Service5.png";
import s6 from "../../img/icons/image/Service6.png";
import s7 from "../../img/icons/image/Service7.png";
import s8 from "../../img/icons/image/Service8.jpg";


const ServisOur = () => {
  const textAnimation = {
    left:{
      x:200,
      opacity:0
  },
    hidden: {
      x: -100,
      opacity: 0,
    },
    visible: custom =>({
      x:0,
      opacity:1,
      transition: {delay: custom * 0.5}
    }),
  }
  const {t} = useTranslation();
  const items = [
    { title: t("Folding furniture"), img: s1 },
    { title: t("Installation of cornice"), img: s3 },
    { title: t("Installing the TV"), img: s4 },
    { title: t("Plasterboard works"), img: s5 },
    { title: t("Delivery + moving assistance"), img: s6 },
    { title: t("Floor installation"), img: s7 },
    { title: t("Snow removal"), img: s8 },
  ];
  return (
    <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{amount: 0.2, once: true}} 
        className="service-main">

      <div className="container">
        <motion.div viewport={{ once: true }} custom={1} variants={textAnimation} className="title-service">
          <h1>{t("Services")}</h1>
          <p>{t("Our craftsmen have experience working with various home projects. Wewe work promptly, using high-quality materials and tools,to ensure maximum durability and comfort")}
            
          </p>
        </motion.div>
        <motion.div viewport={{ once: true }} custom={2} variants={textAnimation}className="coll-service">
          {items.map((itm, index) => {
            return ( 
              <div className="service-block-img">
                <img key={index} src={itm.img} alt={itm.title} />
                <div className="text-service"><span>{itm.title}</span></div>
              </div>
            );
          })}
        </motion.div>
        <motion.div style={{ willChange: 'auto', transform: 'none' }}  viewport={{ once: true }}  custom={3} whileInView="visible" initial="left"className="title-service">
         
          <p>
          {t("Didn't find the service you were looking for? We can help with many other tasks even if they are not listed. Write to us and we will find a soluation for your project!")}
          </p>
          <div className="btn-about" >
                        <BtnCall  text={t("Get in touch")}/>
                        </div>
          {/* <motion.div  viewport={{ once: true }}  custom={4} variants={textAnimation} className="btn-about">
                        <BtnCall  text={t("Get in touch")}/>
                        </motion.div> */}
        </motion.div>
      </div>
    </motion.div>
  );
};

export default ServisOur;
