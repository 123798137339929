import React from "react";
import { useState, useEffect, useRef } from "react";
import logo from "../../img/logo.svg";
import ContUs from "../../HelpComp/ContUs/ContUs";
// import { NavLink, Router } from "react-router-dom";
import BtnCall from "../../HelpComp/BtnCall/BtnCall";
import "./Navbar.css";
import ListLink from "../../HelpComp/ListLink/ListLink";
import ChangeLang from "../ChangeLang/ChangeLang";
import { useTranslation } from "react-i18next";
import { Link, Element } from "react-scroll";
import Mob from "../../HelpComp/Mob/Mob";

// import { useState } from "react";

const Navbar = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  // const [isHovered, setIsHovered] = useState(false); // Статус наведения курсора
  const [isFixed, setIsFixed] = useState(false); // Фиксация меню при скроллинге
  // const menuRef = useRef(null); // Ссылка на бургер-меню

  const toggleMenu = (e) => {
    setIsOpen(!isOpen);
  };

  // useEffect(() => {
  //   let timer;

  //   if (!isHovered  ) {
  //     // Если курсор не на блоке, запускаем таймер на закрытие
  //     timer = setTimeout(() => {
  //       setIsOpen(false); // Закрыть бургер через 5 секунд
  //     }, 5000);
  //   }

  //   return () => clearTimeout(timer); // Очищаем таймер при наведении курсора
  // }, [isHovered]);

  // Обработка клика вне области меню для его закрытия
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (menuRef.current && !menuRef.current.contains(event.target)) {
  //       setIsOpen(false); // Закрыть бургер, если клик вне области
  //     }
  //   };

  //   document.addEventListener('mousedown', handleClickOutside);

  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, []);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 700) {
        // setIsOpen(!isOpen);
        setIsFixed(true); // Фиксировать меню при прокрутке ниже 50px
      } else {
        setIsFixed(false); // Убрать фиксацию, если скролл выше
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll); // Удаление обработчика при размонтировании
    };
  }, []);

  // Обработка скроллинга - фиксируем меню при прокрутке вниз

  // const handleMouseEnter = () => {
  //   setIsHovered(true); // Курсор на блоке, ничего не делаем
  // };

  // const handleMouseLeave = () => {
  //   setIsHovered(false); // Курсор ушел, запустится таймер на закрытие
  // };

  return (
    <div className="main">
      <div className="nav_main">
        <div className="logo">
          <img src={logo} alt="logo" />
          <span>D&S Handyman!</span>
        </div>

        <div
          className={`menu-icon ${isOpen ? "open" : ""}`}
          onClick={toggleMenu}
        >
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        {isFixed ? (
              <Mob />
            ) : ""
              
            }
        <div style={{ display: "flex" }}>
          <ul
         
            

            className={`nav-links ${isOpen ? "active" : ""}`}
          >
            <div className="logo">
              <img src={logo} alt="logo" />
              <span>D&S Handyman!</span>
            </div>
            {isFixed ? (
              <Mob />
            ) : (
              <ul className="mob">
                <li>
                  <Link to="about-us" smooth={true} duration={500}>
                    {t("About us")}
                  </Link>
                </li>
                <li>
                  <Link to="services" smooth={true} duration={500}>
                    {t("Services")}
                  </Link>
                </li>
                <li>
                  <Link to="contact" smooth={true} duration={500}>
                    {t("Contacts")}
                  </Link>
                </li>
              </ul>
            )}

            <div className={`${isOpen ? "conts" : "none"}`}>
              <div className="text__cont">
                <p>{t("Call")}: </p>
                <p>{t("Email")}: 
                dshandyman05@gmail.com</p>
                <p>
                  {t("Address Canada, province of Alberta, region of Calgary")}
                </p>
                <ListLink />
              </div>
            </div>
          </ul>
          <div>
            <ChangeLang />
          </div>
        </div>

        <div className="btn-about">
          <BtnCall text={t("Get in touch")} />
        </div>
      </div>
    </div>
    // </div>
  );
};

export default Navbar;
