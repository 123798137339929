import { motion } from "framer-motion";
import ContUs from "../../HelpComp/ContUs/ContUs";
import ModalForm from "../../HelpComp/Modal/ModalForm";

import  './none.css';
const None = () => {

    const textAnimation = {
        hidden: {
          x: -100,
          opacity: 0,
        },
        visible: custom =>({
          x:0,
          opacity:1,
          transition: {delay: custom * 0.5}
        }),
      }
    return (

        <motion.section 
        initial="hidden"
        whileInView="visible"
        viewport={{amount: 0.2, once: true}}
        className="none-main">
            <div className="container">
                <div className="ara">
                <motion.div viewport={{ once: true }}  custom={1} variants={textAnimation} style = {{paddingTop: "10px"}} className="block-1">
                <ContUs />
                </motion.div>
                <motion.div viewport={{ once: true }} custom={2} variants={textAnimation}  className="block-2">
                    <ModalForm />
                </motion.div>
                </div>
            </div>
        </motion.section>
      );
}
 
export default None;