import React from "react";
import { Link, Element } from 'react-scroll';
// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import Home from "./Page/Home";
import Header from "./components/Header/Header";
import AboutUs from "./components/AboutUs/AboutUs";
import ServisOur from "./components/ServisOur/ServisOur";
import "./App.css";
import Footer from "./components/Footer/Footer";
import None from "./components/none/none";
import Reviews from "./components/Reviews/Rewiews";

function App() {
  return (
    <>
    <Header />
      <Element name="about-us">
        <AboutUs />
      </Element>
      <Element name="services">
        <ServisOur />
      </Element>
      <Element name="contact">
        <None />
      </Element>
      <Footer />
    </>
  );
}

export default App;
