import ListLink from "../../HelpComp/ListLink/ListLink";
import logo from "../../img/logo.svg";

import { useTranslation } from "react-i18next";
import './Footer.css';
import { motion } from "framer-motion";
 
 const Footer = () => {
    const { t } = useTranslation();
    const textAnimation = {
        hidden: {
          x: -100,
          opacity: 0,
        },
        visible: custom =>({
          x:0,
          opacity:1,
          transition: {delay: custom * 0.5}
        }),
      }
    return (
        
        <motion.section 
        initial="hidden"
        whileInView="visible"
        viewport={{amount: 0.2,once: true}} className="footer-main">
            <div className="container">
                <div className="footer">
            <motion.div viewport={{ once: true }} custom={1} variants={textAnimation} className="footer-call">
                {/* <p>{t("Call")}<span>(406) 555-0120</span> </p> */}
                <p> {t("Email")}<p><span>dshandyman05@gmail.com</span></p></p>
            </motion.div>
            <motion.div  viewport={{ once: true }} custom={2} variants={textAnimation} className="footer-block">
                <a href="/#" ><img className="footer-logo" src={logo} alt="logo" /> </a>
                <ListLink/>
            </motion.div>
            <motion.div  viewport={{ once: true }} custom={3} variants={textAnimation} className="footer-adress">
                <p>{t("Addres")}:</p>
                <p> {t("Canada, province of Alberta")},<p>{t("region of Calgary")}</p> </p>
            </motion.div>
            </div>
            </div>
        </motion.section>
      );
 }
  
 export default Footer;