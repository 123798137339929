import React, { useState, useEffect} from "react";
import { Link, Element } from "react-scroll";
import { useTranslation } from "react-i18next";

const Mob = () => {


    const { t } = useTranslation();
    
    // const [isFixed, setIsFixed] = useState(false);
    return (
        <>
        <ul   className= "mob-fix">
            <li>
           <Link to="Header" smooth={true} duration={500}>
              {t("Home")}
             </Link>
          </li> 

            <li>
              <Link to="about-us" smooth={true} duration={500}>
                {t("About us")}
              </Link>
            </li>
            <li>
              <Link to="services" smooth={true} duration={500}>
                {t("Services")}
              </Link>
            </li>
            <li>
              <Link to="contact" smooth={true} duration={500}>
                {t("Contacts")}
              </Link>
            </li>
            </ul>
        </>
      );
}
 
export default Mob;