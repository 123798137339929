import React from "react";
import { motion } from 'framer-motion';
import {  useTranslation } from "react-i18next";
import Navbar from "../Navbar/Navbar";
import "./Header.css";
import ContUs from "../../HelpComp/ContUs/ContUs";
import ListLink from "../../HelpComp/ListLink/ListLink";
import BtnCall from "../../HelpComp/BtnCall/BtnCall";

const Header = () => {
  const {t} = useTranslation();
const textAnimation = {
  left:{
    x:200,
    opacity:0
},
  hidden: {
    x: -100,
    opacity: 0,
  },
  visible: custom =>({
    x:0,
    opacity:1,
    transition: {delay: custom * 0.2}
  }),
}
  return (
    <motion.section
    initial="hidden"
    whileInView="visible"
    viewport={{amount: 0.2, once: true}}
    className="Header">
      <div className="container">
        <Navbar />

        <div className="header__title">
          <motion.div viewport={{ once: true }}  custom={1} variants={textAnimation} style={{ willChange: 'auto', transform: 'none' }}  className="header__text">
           <h1> D&S Handyman!</h1>
            <h2>{t('A reliable assistant for your home')}</h2>
            <div className="list">
              <ListLink/>
            </div>
            {/* <div className="">
              <BtnCall text={t("Free consultation")}  />
            </div> */}
              <div className=""  >
               <BtnCall  text={t("Free consultation")}/>
              </div>
              </motion.div>
          
          <motion.div viewport={{ once: true }}   whileInView="visible" initial="left" custom={2} variants={textAnimation}
        className="cont">
          <ContUs />
          </motion.div>
          
        </div>
      </div>
   
    </motion.section>
  );
};

export default Header;
