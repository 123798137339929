// src/components/YourComponent.js
import React, { useState,useEffect } from "react";
import "./ChangeLang.css";
import { useTranslation } from "react-i18next";
import use from "../../img/icons/use.png";
import ua from "../../img/icons/ukraine.png";

const ChangeLang = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  // Функция для переключения видимости меню
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Функция для смены языка
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setIsOpen(false); // Закрыть меню после выбора языка
  };

  // Закрытие меню при клике вне его
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.dropdown-container')) {
        setIsOpen(false);
      }
    };

    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="dropdown-container" style={{ position: 'relative',  fontSize: "18px"  }}>
      {/* Кнопка с текущим языком */}
      <div className="dropdown-button" onClick={toggleMenu} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
        {i18n.language === "en" ? (
          <div style={{ width: "70px", display: "flex", alignItems: "center" }}>
            <img style={{ width: "20px", marginRight: "8px" }} src={use} alt="English" />
            Eng
          </div>
        ) : (
          <div style={{  width: "70px" , display: "flex", alignItems: "center" }}>
            <img style={{ width: "20px", marginRight: "8px" }} src={ua} alt="Украинский" />
            Ua
          </div>
        )}
      </div>

      {/* Выпадающее меню */}
      {isOpen && (
        <ul className="dropdown-menu show">
          {i18n.language === "en" ? (
            <li className="menu-item" onClick={() => changeLanguage('ua')} style={{   width: "70px", cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
              <img style={{ width: '20px', marginRight: '10px' }} src={ua} alt="Украинский" />
              Ua
            </li>
          ) : (
            <li className="menu-item" onClick={() => changeLanguage('en')} style={{  width: "70px" ,cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
              <img style={{ width: '20px', marginRight: '10px' }} src={use} alt="English" />
              Eng
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

export default ChangeLang;





// const ChangeLang = () => {
//   const { i18n } = useTranslation();

//   const [isOpen, setIsOpen] = useState(false);
//   // const [leng, setLeng] = useState('')
//   const toggleMenu = () => setIsOpen(!isOpen);

//   const changeLanguage = (lng) => {
//     i18n.changeLanguage(lng);
//     setIsOpen(false); // Закрыть меню после выбора языка
//   };

//   return (
//     <div className="dropdown-container">
//       <div className="dropdown-button" onClick={toggleMenu}>
//         <div>
//           {i18n.language === "en" ? (
//             <div style={{ display: "flex", alignItems: "center" }}>
//               <img style={{ width: "20px" }} src={use} alt="English" />
//               Eng
//             </div>
//           ) : (
//             <div style={{ display: "flex", alignItems: "center" }}>
//               <img style={{ width: "20px" }} src={ua} alt="Украинский" />
//               Ua
//             </div>
//           )}
//         </div>

//         <div className={`dropdown-menu show ${isOpen ? "show" : "hide"}`}>
//           {i18n.language === "en" ? (
//             <li className="menu-item " onClick={() => changeLanguage("ua")}>
//               {" "}
//               <img style={{ width: "20px" }} src={ua} alt="Украинский" /> Ua
//             </li>
//           ) : (
//             <li className="menu-item " onClick={() => changeLanguage("en")}>
//               {" "}
//               <img style={{ width: "20px" }} src={use} alt="English" />
//               Eng
//             </li>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ChangeLang;
